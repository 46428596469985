import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import commonStyles from 'styles/common.module.scss';
import Container from 'react-bootstrap/Container';
import SiteIndex from 'components/site-index';
import { useDocumentTitle } from 'usehooks-ts';
export default function Page() {
    useDocumentTitle('Hera Nails & Spa Intranet');
    return (React.createElement(Container, { as: "main", fluid: "lg" },
        React.createElement(Breadcrumb, null,
            React.createElement(Breadcrumb.Item, { active: true }, "Home")),
        React.createElement("h1", { className: commonStyles.title }, "Hera Nails & Spa Intranet"),
        React.createElement(SiteIndex, null)));
}
